import React from 'react';
import Svg from './Svg';

export default function MaterialIconDelete(props) {
  return (
    <Svg
      {...props}
      d="M30.45,98c-1.944,-0 -3.597,-0.681 -4.958,-2.042c-1.361,-1.361 -2.042,-3.014 -2.042,-4.958l0,-66.5l-4.783,-0l-0,-7l21.933,-0l-0,-3.5l30.8,-0l-0,3.5l21.933,-0l0,7l-4.783,-0l-0,66.5c-0,1.867 -0.7,3.5 -2.1,4.9c-1.4,1.4 -3.033,2.1 -4.9,2.1l-51.1,-0Zm51.1,-73.5l-51.1,-0l0,66.5l51.1,-0l-0,-66.5Zm-38.733,56.467l7,-0l-0,-46.55l-7,-0l-0,46.55Zm19.366,-0l7,-0l0,-46.55l-7,-0l0,46.55Zm-31.733,-56.467l0,66.5l0,-66.5Z"
    />
  );
}
