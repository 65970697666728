import React from 'react';
import Svg from './Svg';

export default function Comments(props) {
  return (
    <Svg
      {...props}
      d="M67.873,87.33c0,-0.001 0.001,-0.001 0.001,-0.002c0.12,-0.233 0.276,-0.452 0.509,-0.452l25.811,0c3.33,0 6.25,-2.941 6.25,-6.811l0,-64.477c0,-3.87 -2.92,-6.812 -6.25,-6.812l-76.388,0c-3.33,0 -6.25,2.942 -6.25,6.812l0,64.477c0,3.87 2.92,6.811 6.25,6.811l25.811,0c0.233,0 0.389,0.219 0.509,0.452l6.431,12.44c0.509,0.98 1.229,1.797 2.074,2.384c3.019,2.104 6.993,1.132 8.814,-2.388l6.428,-12.434Zm-4.935,-2.551l-6.427,12.432c-0.16,0.309 -0.439,0.567 -0.704,0.382c-0.003,-0.001 -0.005,-0.003 -0.008,-0.005c-0.131,-0.091 -0.232,-0.228 -0.311,-0.38l-6.426,-12.429c-1.136,-2.202 -3.232,-3.458 -5.445,-3.458l-25.811,0c-0.517,0 -0.695,-0.655 -0.695,-1.256l0,-64.477c0,-0.601 0.178,-1.256 0.695,-1.256l76.388,0c0.517,0 0.695,0.655 0.695,1.256l0,64.477c0,0.601 -0.178,1.256 -0.695,1.256l-25.811,0c-2.213,0 -4.309,1.256 -5.446,3.459l0.001,-0.001Zm-20.826,-26.114l27.778,-0c1.533,-0 2.778,-1.244 2.778,-2.777c-0,-1.534 -1.245,-2.778 -2.778,-2.778l-27.778,-0c-1.533,-0 -2.778,1.244 -2.778,2.778c-0,1.533 1.245,2.777 2.778,2.777Zm-0,-16.12l27.778,-0c1.533,-0 2.778,-1.245 2.778,-2.778c-0,-1.533 -1.245,-2.778 -2.778,-2.778l-27.778,-0c-1.533,-0 -2.778,1.245 -2.778,2.778c-0,1.533 1.245,2.778 2.778,2.778Z"
    />
  );
}
