import React from 'react';
import Svg from './Svg';

export default function ArrowLeft(props) {
  return (
    <Svg
      {...props}
      d="M77.526,10.001l-46.981,46.981l46.981,46.981l3.929,-3.928l-43.053,-43.053c0,0 43.053,-43.052 43.053,-43.052l-3.929,-3.929Z"
    />
  );
}
