import React from 'react';
import Svg from './Svg';

export default function MaterialIconLooksTwo(props) {
  return (
    <Svg
      {...props}
      d="M44.5,76.3l23,-0l-0,-6l-17,-0l-0,-11.5l11,-0c1.6,-0 3,-0.6 4.2,-1.8c1.2,-1.2 1.8,-2.6 1.8,-4.2l-0,-11.1c-0,-1.6 -0.6,-3 -1.8,-4.2c-1.2,-1.2 -2.6,-1.8 -4.2,-1.8l-17,-0l-0,6l17,-0l-0,11.1l-11,-0c-1.6,-0 -3,0.6 -4.2,1.8c-1.2,1.2 -1.8,2.6 -1.8,4.2l-0,17.5Zm-18.5,15.7c-1.6,-0 -3,-0.6 -4.2,-1.8c-1.2,-1.2 -1.8,-2.6 -1.8,-4.2l-0,-60c-0,-1.6 0.6,-3 1.8,-4.2c1.2,-1.2 2.6,-1.8 4.2,-1.8l60,-0c1.6,-0 3,0.6 4.2,1.8c1.2,1.2 1.8,2.6 1.8,4.2l-0,60c-0,1.6 -0.6,3 -1.8,4.2c-1.2,1.2 -2.6,1.8 -4.2,1.8l-60,-0Zm-0,-6l60,-0l-0,-60l-60,-0l-0,60Zm-0,-60l-0,60l-0,-60Z"
    />
  );
}
