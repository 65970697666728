import React from 'react';
import Svg from './Svg';

export default function Graph(props) {
  return (
    <Svg
      {...props}
      d="M67.925,36.95l-25.476,0c-0.898,0 -1.626,0.728 -1.626,1.626l0,21.606l-25.477,0c-0.898,0 -1.626,0.728 -1.626,1.626l0,33.219l-6.118,0c-0.897,0 -1.626,0.729 -1.626,1.626c0,0.898 0.729,1.626 1.626,1.626l61.893,0c0.018,0.001 0.037,0.001 0.056,0.001l27.102,0c0.019,0 0.038,0 0.056,-0.001l7.689,0c0.897,0 1.626,-0.728 1.626,-1.626c0,-0.897 -0.729,-1.626 -1.626,-1.626l-6.119,0l0,-79.681c0,-0.898 -0.728,-1.626 -1.626,-1.626l-27.102,0c-0.898,0 -1.626,0.728 -1.626,1.626l0,21.604Zm27.102,58.077l-23.85,0l0,-78.055l23.85,0l0,78.055Zm-50.952,0l23.85,0l0,-54.825l-23.85,0l0,54.825Zm-27.103,-31.593l0,31.593l23.851,0l0,-31.593l-23.851,0Z"
    />
  );
}
