import React from 'react';
import Svg from './Svg';

export default function MaterialIconUndo(props) {
  return (
    <Svg
      {...props}
      d="M58.333,37.333c-12.366,0 -23.566,4.62 -32.2,12.134l-16.8,-16.8l0,42l42,-0l-16.893,-16.894c6.487,-5.413 14.747,-8.773 23.893,-8.773c16.52,0 30.567,10.78 35.467,25.667l11.06,-3.64c-6.487,-19.554 -24.827,-33.694 -46.527,-33.694Z"
    />
  );
}
