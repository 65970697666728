import React from 'react';
import Svg from './Svg';

export default function MaterialIconFormatAlignCenter(props) {
  return (
    <Svg
      {...props}
      d="M20,92l-0,-6l72,-0l-0,6l-72,-0Zm16.4,-16.5l-0,-6l39.3,-0l-0,6l-39.3,-0Zm-16.4,-16.5l-0,-6l72,-0l-0,6l-72,-0Zm16.4,-16.5l-0,-6l39.3,-0l-0,6l-39.3,-0Zm-16.4,-16.5l-0,-6l72,-0l-0,6l-72,-0Z"
    />
  );
}
