import React from 'react';
import Svg from './Svg';

export default function MapPin(props) {
  return (
    <Svg
      {...props}
      d="M95.896,47.915c0,-21.887 -18.01,-39.897 -39.896,-39.897c-21.886,0 -39.896,18.01 -39.896,39.897c-0,36.488 38.154,57.585 38.154,57.585c1.085,0.594 2.399,0.594 3.484,-0c0,-0 38.154,-21.097 38.154,-57.585Zm-7.254,-0c0,28.145 -25.649,45.883 -32.642,50.196c-6.993,-4.313 -32.642,-22.051 -32.642,-50.196c-0,-17.907 14.735,-32.643 32.642,-32.643c17.907,0 32.642,14.736 32.642,32.643Zm-14.507,-0c-0,-9.949 -8.187,-18.135 -18.135,-18.135c-9.948,0 -18.135,8.186 -18.135,18.135c0,9.948 8.187,18.134 18.135,18.134c9.948,0 18.135,-8.186 18.135,-18.134Zm-7.254,-0c-0,5.969 -4.912,10.88 -10.881,10.88c-5.969,0 -10.881,-4.911 -10.881,-10.88c0,-5.969 4.912,-10.881 10.881,-10.881c5.969,-0 10.881,4.912 10.881,10.881Z"
    />
  );
}
