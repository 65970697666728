import React from 'react';
import Svg from './Svg';

export default function MaterialIconFormatListBulleted(props) {
  return (
    <Svg
      {...props}
      d="M25.1,86c-1.4,-0 -2.6,-0.483 -3.6,-1.45c-1,-0.967 -1.5,-2.117 -1.5,-3.45c-0,-1.4 0.5,-2.6 1.5,-3.6c1,-1 2.2,-1.5 3.6,-1.5c1.333,-0 2.483,0.5 3.45,1.5c0.967,1 1.45,2.2 1.45,3.6c-0,1.333 -0.483,2.483 -1.45,3.45c-0.967,0.967 -2.117,1.45 -3.45,1.45Zm14.9,-2l-0,-6l52,-0l-0,6l-52,-0Zm-14.9,-23c-1.4,-0 -2.6,-0.483 -3.6,-1.45c-1,-0.967 -1.5,-2.15 -1.5,-3.55c-0,-1.4 0.5,-2.583 1.5,-3.55c1,-0.967 2.2,-1.45 3.6,-1.45c1.333,0 2.483,0.5 3.45,1.5c0.967,1 1.45,2.167 1.45,3.5c-0,1.333 -0.483,2.5 -1.45,3.5c-0.967,1 -2.117,1.5 -3.45,1.5Zm14.9,-2l-0,-6l52,0l-0,6l-52,-0Zm-15,-23c-1.4,0 -2.583,-0.483 -3.55,-1.45c-0.967,-0.967 -1.45,-2.15 -1.45,-3.55c-0,-1.4 0.483,-2.583 1.45,-3.55c0.967,-0.967 2.15,-1.45 3.55,-1.45c1.4,0 2.583,0.483 3.55,1.45c0.967,0.967 1.45,2.15 1.45,3.55c-0,1.4 -0.483,2.583 -1.45,3.55c-0.967,0.967 -2.15,1.45 -3.55,1.45Zm15,-2l-0,-6l52,0l-0,6l-52,0Z"
    />
  );
}
