import React from 'react';
import Svg from './Svg';

export default function MaterialIconFormatAlignLeft(props) {
  return (
    <Svg
      {...props}
      d="M20,92l0,-6l72,0l0,6l-72,0Zm0,-16.5l0,-6l47.2,0l0,6l-47.2,0Zm0,-16.5l0,-6l72,0l0,6l-72,0Zm0,-16.5l0,-6l47.2,0l0,6l-47.2,0Zm0,-16.5l0,-6l72,0l0,6l-72,0Z"
    />
  );
}
