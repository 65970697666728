import React from 'react';
import Svg from './Svg';

export default function MaterialIconLinkOff(props) {
  return (
    <Svg
      {...props}
      d="M29.248,34.698l-14.098,-14.098l4.3,-4.3l75.1,75.1l-4.3,4.3l-39.4,-39.4l-9.5,0l-0,-6l3.5,0l-10.752,-10.752c-2.704,0.507 -5.087,1.791 -7.148,3.852c-2.734,2.734 -4.1,6.034 -4.1,9.9c-0,3.866 1.366,7.166 4.1,9.9c2.734,2.734 6.034,4.1 9.9,4.1l17,0l-0,6l-17,0c-5.534,0 -10.25,-1.95 -14.15,-5.85c-3.9,-3.9 -5.85,-8.616 -5.85,-14.15c-0,-4.8 1.484,-9.034 4.45,-12.7c2.2,-2.719 4.849,-4.686 7.948,-5.902Zm54.602,37.502l-4.9,-4.9c3.4,-0.666 6.234,-2.266 8.5,-4.8c2.266,-2.534 3.4,-5.5 3.4,-8.9c-0,-3.866 -1.366,-7.166 -4.1,-9.9c-2.734,-2.734 -6.034,-4.1 -9.9,-4.1l-15.5,0l-0,-6l15.5,0c5.534,0 10.25,1.95 14.15,5.85c3.9,3.9 5.85,8.616 5.85,14.15c-0,4.134 -1.2,7.866 -3.6,11.2c-2.4,3.334 -5.534,5.8 -9.4,7.4Zm-15.6,-15.6l-6,-6l10.1,0l-0,6l-4.1,0Z"
    />
  );
}
