import React from 'react';
import Svg from './Svg';

export default function Payment(props) {
  return (
    <Svg
      {...props}
      d="M66.184,43.961l30.554,0c0.513,0 0.928,0.416 0.928,0.928l0,55.555c0,1.534 1.245,2.778 2.778,2.778c1.533,0 2.777,-1.244 2.777,-2.778l0,-55.555c0,-3.577 -2.896,-6.483 -6.483,-6.483l-30.554,0l0,-23.145c0,-3.587 -2.896,-6.483 -6.483,-6.483l-44.445,0c-3.578,0 -6.477,2.895 -6.477,6.483l0,85.183c0,1.534 1.244,2.778 2.777,2.778c1.533,0 2.778,-1.244 2.778,-2.778l0,-85.183c0,-0.512 0.412,-0.928 0.922,-0.928l44.445,0c0.513,0 0.928,0.415 0.928,0.928l0,25.826c-0.002,0.032 -0.002,0.064 -0.002,0.096c0,0.033 0,0.065 0.002,0.097l0,59.164c0,1.534 1.244,2.778 2.777,2.778c1.533,0 2.778,-1.244 2.778,-2.778l0,-56.483Zm-39.814,44.446l14.816,0c1.533,0 2.778,-1.244 2.778,-2.778c0,-1.533 -1.245,-2.777 -2.778,-2.777l-14.816,0c-1.533,0 -2.778,1.244 -2.778,2.777c0,1.534 1.245,2.778 2.778,2.778Zm51.851,-7.407l7.406,0c1.533,0 2.778,-1.245 2.778,-2.778c0,-1.533 -1.245,-2.778 -2.778,-2.778l-7.406,0c-1.533,0 -2.777,1.245 -2.777,2.778c0,1.533 1.244,2.778 2.777,2.778Zm-44.444,-11.111l14.817,0c1.533,0 2.777,-1.245 2.777,-2.778c0,-1.533 -1.244,-2.778 -2.777,-2.778l-14.817,0c-1.533,0 -2.778,1.245 -2.778,2.778c0,1.533 1.245,2.778 2.778,2.778Zm44.444,-7.408l7.406,0c1.533,0 2.778,-1.245 2.778,-2.778c0,-1.533 -1.245,-2.777 -2.778,-2.777l-7.406,0c-1.533,0 -2.777,1.244 -2.777,2.777c0,1.533 1.244,2.778 2.777,2.778Zm-51.851,-29.629l14.816,0c1.533,0 2.778,-1.245 2.778,-2.778c0,-1.533 -1.245,-2.778 -2.778,-2.778l-14.816,0c-1.533,0 -2.778,1.245 -2.778,2.778c0,1.533 1.245,2.778 2.778,2.778Z"
    />
  );
}
