import React from 'react';
import { Button } from '@chakra-ui/react';
import { Icon } from './Icon';
import { useBreakpointValue } from '@chakra-ui/react';
import { PRIMARY_SCHEME } from '../appTheme';

export const AgiresButton = React.forwardRef(({
  iconName,
  iconWidth,
  colorScheme,
  display,
  margin,
  size,
  as,
  href,
  width,
  variant,
  backgroundColor,
  children,
  height,
  py,
  px,
  type,
  onClick,
  whiteSpace,
  minWidth,
  title,
  paddingInlineStart,
  paddingInlineEnd,
  borderRadius,
  flexGrow,
  flexShrink,
  iconMargin,
  disabled,
  target,
  iconRight,
}, ref) => {

  const breakPointSize = useBreakpointValue(
    {
      base: size && size.base,
      xs: size && size.xs,
      sm: size && size.sm,
      md: size && size.md,
      lg: size && size.lg,
    }, {
      fallback: 'md',
    });

  return (
    <Button
      ref={ref}
      paddingInlineStart={paddingInlineStart ? paddingInlineStart : "1em"}
      paddingInlineEnd={paddingInlineEnd ? paddingInlineEnd : "1em"}
      fontWeight="bold"
      minWidth={minWidth ? minWidth : "8em"}
      textAlign="center"
      colorScheme={colorScheme ? colorScheme : PRIMARY_SCHEME}
      display={display ? display : "inline-flex"}
      margin={margin}
      size={size ? size : breakPointSize ? breakPointSize : null}
      as={as}
      href={href}
      width={(display === "flex" ? "100%" : ( width ? width : null ))}
      variant={variant ? variant : 'solid'}
      backgroundColor={backgroundColor}
      whiteSpace={whiteSpace ? whiteSpace : "revert"}
      height={height}
      py={py}
      pl={px}
      pr={px}
      type={type}
      onClick={onClick}
      title={title}
      borderRadius={borderRadius}
      flexGrow={flexGrow}
      flexShrink={flexShrink}
      disabled={disabled}
      target={target}
      rel={target ? "noopener noreferrer" : null}
    >
      {iconName && !iconRight &&
        <Icon
          iconName={iconName}
          iconWidth={iconWidth ? iconWidth : "1.75em"}
          iconColor="currentColor"
          margin={iconMargin ? iconMargin : "0 .25em 0 0"}
        />
      }
        {children}
      {iconName && iconRight &&
        <Icon
          iconName={iconName}
          iconWidth={iconWidth ? iconWidth : "1.75em"}
          iconColor="currentColor"
          margin={iconMargin ? iconMargin : "0 .25em 0 0"}
        />
      }
    </Button>
  )
});