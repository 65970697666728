import React from 'react';
import Svg from './Svg';

export default function Calendar(props) {
  return (
    <Svg
      {...props}
      d="M75.491,16.251l-38.889,0l0,-4.63c0,-1.533 -1.244,-2.778 -2.777,-2.778c-1.534,0 -2.778,1.245 -2.778,2.778l0,4.63l-12.037,0c-3.577,0 -6.484,2.896 -6.484,6.483l0,74.072c0,3.577 2.907,6.483 6.484,6.483l74.072,0c3.587,0 6.483,-2.906 6.483,-6.483l0,-55.444c0.002,-0.037 0.002,-0.074 0.002,-0.111c0,-0.038 0,-0.075 -0.002,-0.112l0,-18.405c0,-3.587 -2.896,-6.483 -6.483,-6.483l-12.035,0l0,-4.63c0,-1.533 -1.245,-2.778 -2.778,-2.778c-1.533,0 -2.778,1.245 -2.778,2.778l0,4.63Zm18.519,27.777l0,52.778c0,0.512 -0.415,0.928 -0.928,0.928l-74.072,0c-0.512,0 -0.928,-0.416 -0.928,-0.928l0,-52.778l75.928,0Zm-12.963,-22.222l0,4.632c0,1.533 -1.245,2.778 -2.778,2.778c-1.533,-0 -2.778,-1.245 -2.778,-2.778l0,-4.632l-38.889,-0l0,4.632c0,1.533 -1.244,2.778 -2.777,2.778c-1.534,-0 -2.778,-1.245 -2.778,-2.778l0,-4.632l-12.037,-0c-0.512,-0 -0.928,0.415 -0.928,0.928l0,15.739l75.928,-0l0,-15.739c0,-0.513 -0.415,-0.928 -0.928,-0.928l-12.035,-0Z"
    />
  );
}
