import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  display: inline-block;
  ${(props) => (props.cursor ? 'cursor: ' + props.cursor + ';' : null)};
  ${(props) => (props.verticalAlignMiddle ? 'vertical-align: middle;' : null)}
  ${(props) => (props.margin ? 'margin: ' + props.margin + ';' : null)}
  width: ${(props) => props.iconWidth};
  min-width: ${(props) => props.iconWidth};
  height: ${(props) => props.iconWidth};
  transition: ${(props) => props.iconTransition};
  max-width: 100%;
  ${(props) =>
    props.borderColor
      ? 'border: solid 1px ' + props.theme[props.borderColor] + ';'
      : null};
`;
const Path = styled.path`
  fill: ${(props) => {
    if (props.freeIconColor) {
      return props.freeIconColor;
    }
    if (props.iconColor) {
      return props.iconColor;
    }
    return props.theme.lightColor;
  }};
`;
class SvgIcon extends React.Component {
  render() {
    return (
      <Svg
        viewBox="0 0 112 112"
        xmlns="http://www.w3.org/2000/svg"
        iconWidth={this.props.iconWidth ? this.props.iconWidth : '1em'}
        margin={this.props.margin}
        iconAlt={this.props.iconAlt}
        cursor={this.props.cursor}
        onClick={this.props.onClick}
        onMouseOver={this.props.onMouseOver}
        onMouseLeave={this.props.onMouseLeave}
        position={this.props.position}
        verticalAlignMiddle={this.props.verticalAlignMiddle}
        borderColor={this.props.borderColor}
        iconTransition={this.props.iconTransition}
        style={{
          fillRule: 'evenodd',
        }}
      >
        {this.props.iconAlt && <title>{this.props.iconAlt}</title>}
        <Path
          freeIconColor={this.props.freeIconColor}
          iconColor={
            this.props.iconColor ? this.props.iconColor : 'currentColor'
          }
          d={this.props.d}
        />
      </Svg>
    );
  }
}

export default SvgIcon;
