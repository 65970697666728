import React from 'react';
import Svg from './Svg';

export default function Phone(props) {
  return (
    <Svg
      {...props}
      d="M13.192,35.578c0,35.462 28.75,64.212 64.212,64.212l9.173,-0c6.71,-0 12.231,-5.521 12.231,-12.231c-0,-0 -0,-5.594 -0,-5.594c-0,-3.507 -2.386,-6.565 -5.791,-7.414c0.001,0 -18.031,-4.509 -18.031,-4.509c-0,0 -0,0 -0,-0c-2.99,-0.748 -6.13,0.374 -7.972,2.834c0.002,-0.002 -3.953,5.269 -3.953,5.269c0,0 0,0 0,0c-0.338,0.451 -0.903,0.708 -1.432,0.514c-12.659,-4.654 -22.652,-14.647 -27.306,-27.306c-0.194,-0.529 0.063,-1.094 0.514,-1.432l5.271,-3.955c2.463,-1.839 3.58,-4.986 2.832,-7.971c-0,0.001 -4.509,-18.031 -4.509,-18.031c-0,-0 -0,-0 -0,-0c-0.848,-3.391 -3.919,-5.789 -7.413,-5.79l-5.595,0c-6.71,0 -12.231,5.521 -12.231,12.231l0,9.173Zm6.116,0l-0,-9.173c-0,-3.355 2.76,-6.115 6.115,-6.115l5.593,-0c0.699,-0 1.313,0.479 1.482,1.157l4.51,18.034c0.149,0.594 -0.072,1.222 -0.564,1.589l-5.277,3.959c-2.615,1.961 -3.711,5.366 -2.584,8.433c-0,0 -0,0.001 -0,0.001c5.273,14.342 16.594,25.663 30.936,30.936c0,0 0.001,0 0.001,0c3.067,1.127 6.472,0.031 8.434,-2.584l3.956,-5.274c0.368,-0.492 0.995,-0.716 1.593,-0.566l18.033,4.509c0.68,0.17 1.156,0.781 1.156,1.481l0,5.594c0,3.355 -2.76,6.115 -6.115,6.115c-0,0 -9.173,0 -9.173,0c-32.085,0 -58.096,-26.011 -58.096,-58.096Z"
    />
  );
}
