import React from 'react';
import Svg from './Svg';

export default function CheckedBox(props) {
  return (
    <Svg
      {...props}
      d="M15.594,8.778c-3.769,-0 -6.816,3.057 -6.816,6.816l-0,80.812c-0,3.76 3.047,6.816 6.816,6.816c0,0 80.806,0 80.806,0c3.768,0 6.822,-3.058 6.822,-6.816c0,-0 0,-80.812 0,-80.812c0,-3.758 -3.054,-6.816 -6.822,-6.816l-80.806,-0Zm0,5.555l80.806,0c0.698,0 1.267,0.565 1.267,1.261l-0,80.812c-0,0.696 -0.569,1.261 -1.267,1.261l-80.806,-0c-0.697,-0 -1.261,-0.566 -1.261,-1.261c0,-0 0,-80.812 0,-80.812c0,-0.695 0.564,-1.261 1.261,-1.261Zm60.708,27.537l-27.709,26.452c-0,-0 -12.899,-12.313 -12.899,-12.313c-1.109,-1.059 -2.869,-1.018 -3.927,0.091c-1.059,1.109 -1.018,2.868 0.091,3.927l14.817,14.144c1.073,1.025 2.762,1.025 3.836,0l29.628,-28.283c1.109,-1.058 1.149,-2.818 0.091,-3.927c-1.059,-1.109 -2.819,-1.15 -3.928,-0.091Z"
    />
  );
}
