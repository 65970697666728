import React from 'react';
import Svg from './Svg';

export default function MaterialIconFormatUnderlined(props) {
  return (
    <Svg
      {...props}
      d="M28,92l0,-6l56,-0l-0,6l-56,-0Zm28,-16c-6.733,-0 -12.417,-2.3 -17.05,-6.9c-4.633,-4.6 -6.95,-10.233 -6.95,-16.9l0,-32.2l8,0l0,32.4c0,4.4 1.533,8.1 4.6,11.1c3.067,3 6.867,4.5 11.4,4.5c4.533,-0 8.333,-1.5 11.4,-4.5c3.067,-3 4.6,-6.7 4.6,-11.1l-0,-32.4l8,-0l-0,32.2c-0,6.667 -2.317,12.3 -6.95,16.9c-4.633,4.6 -10.317,6.9 -17.05,6.9Z"
    />
  );
}
