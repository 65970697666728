import React from 'react';
import Svg from './Svg';

export default function Documents(props) {
  return (
    <Svg
      {...props}
      d="M8.78,72.003c-0.003,0.053 -0.004,0.106 -0.004,0.159c0,0.053 0.001,0.105 0.004,0.158l0,24.086c0,3.76 3.047,6.816 6.817,6.816l80.805,0c3.768,0 6.822,-3.058 6.822,-6.816l0,-80.812c0,-3.758 -3.054,-6.816 -6.822,-6.816l-80.805,0c-3.77,0 -6.817,3.057 -6.817,6.816l0,56.409Zm5.556,2.936l0,21.467c0,0.695 0.563,1.261 1.261,1.261l80.805,-0c0.699,-0 1.267,-0.565 1.267,-1.261l0,-21.467l-15.755,-0c-0.334,-0 -0.655,0.134 -0.891,0.37l-9.763,9.763c-1.282,1.273 -3.015,1.99 -4.812,1.99l-20.9,-0c-1.797,-0 -3.53,-0.717 -4.813,-1.99l-9.763,-9.763c-0.235,-0.236 -0.556,-0.37 -0.891,-0.37l-15.745,-0Zm83.333,-5.555l0,-53.79c0,-0.696 -0.568,-1.261 -1.267,-1.261l-80.805,-0c-0.698,-0 -1.261,0.566 -1.261,1.261l0,53.79l15.745,-0c1.81,-0 3.545,0.722 4.82,1.997c0,-0 9.75,9.75 9.755,9.755c0.239,0.234 0.559,0.37 0.892,0.37l20.9,-0c0.335,-0 0.657,-0.138 0.897,-0.375l9.75,-9.75c1.275,-1.275 3.01,-1.997 4.819,-1.997l15.755,-0Zm-44.446,-7.968l-12.4,-12.401c-1.084,-1.084 -2.844,-1.084 -3.928,-0c-1.084,1.084 -1.084,2.845 0,3.929l17.117,17.117c0.35,0.359 0.796,0.623 1.296,0.752c0.225,0.058 0.459,0.089 0.696,0.089c0.736,-0 1.443,-0.293 1.964,-0.814l17.139,-17.145c1.084,-1.084 1.083,-2.844 -0.001,-3.928c-1.084,-1.084 -2.844,-1.084 -3.928,0.001l-12.399,12.402l0,-33.701c0,-1.533 -1.245,-2.778 -2.778,-2.778c-1.533,-0 -2.778,1.245 -2.778,2.778l0,33.699Z"
    />
  );
}
