import React from 'react';
import Svg from './Svg';

export default function Contact(props) {
  return (
    <Svg
      {...props}
      d="M41.035,72.62c-13.963,3.806 -26.49,12.91 -34.304,26.434c-0.767,1.328 -0.312,3.028 1.015,3.795c1.328,0.767 3.028,0.312 3.795,-1.015c14.188,-24.555 45.589,-32.962 70.144,-18.774c0.001,0 0.001,0.001 0.001,0.001c7.795,4.5 14.272,10.977 18.772,18.772c0.767,1.328 2.467,1.783 3.795,1.017c1.328,-0.767 1.783,-2.467 1.016,-3.795c-4.988,-8.639 -12.166,-15.817 -20.805,-20.805c-4.324,-2.499 -8.839,-4.365 -13.441,-5.628c7.412,-3.682 13.491,-10.088 16.592,-18.463c0.533,-1.437 -0.202,-3.037 -1.64,-3.569c-1.437,-0.533 -3.037,0.202 -3.569,1.64c-5.395,14.567 -21.575,21.999 -36.142,16.604c-14.567,-5.395 -21.999,-21.574 -16.604,-36.141c4.935,-13.318 19.021,-20.867 32.839,-17.601c1.492,0.353 2.99,-0.572 3.343,-2.064c0.352,-1.492 -0.573,-2.99 -2.065,-3.342c-16.548,-3.912 -33.417,5.128 -39.327,21.077c-6.039,16.307 1.345,34.302 16.585,41.857Zm46.97,-52.243l-8.816,0c-1.533,0 -2.778,1.245 -2.778,2.778c-0,1.533 1.245,2.777 2.778,2.777l8.816,0l-0,8.818c-0,1.533 1.245,2.777 2.778,2.777c1.533,0 2.778,-1.244 2.778,-2.777l-0,-8.818l8.817,0c1.533,0 2.778,-1.244 2.778,-2.777c-0,-1.533 -1.245,-2.778 -2.778,-2.778l-8.817,0l-0,-8.816c-0,-1.533 -1.245,-2.778 -2.778,-2.778c-1.533,0 -2.778,1.245 -2.778,2.778l-0,8.816Z"
    />
  );
}
