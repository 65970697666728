import React from 'react';
import Svg from './Svg';

export default function Filter(props) {
  return (
    <Svg
      {...props}
      d="M16.858,47.462c-5.774,1.272 -10.099,6.423 -10.099,12.577c0,6.153 4.325,11.305 10.1,12.576l0,27.831c0,1.533 1.244,2.778 2.777,2.778c1.533,0 2.778,-1.245 2.778,-2.778l0,-27.831c5.775,-1.271 10.1,-6.423 10.1,-12.576c0,-6.155 -4.326,-11.307 -10.1,-12.577l0,-35.908c0,-1.533 -1.245,-2.778 -2.778,-2.778c-1.533,0 -2.778,1.245 -2.778,2.778l0,35.908Zm36.365,-24.243c-5.775,1.272 -10.101,6.423 -10.101,12.577c-0,6.153 4.326,11.305 10.101,12.577l-0,52.07c-0.001,1.533 1.244,2.777 2.777,2.777c1.533,0 2.778,-1.244 2.778,-2.777l-0,-52.07c5.775,-1.272 10.1,-6.424 10.1,-12.577c0,-6.154 -4.325,-11.305 -10.1,-12.577l0,-11.665c0,-1.533 -1.245,-2.778 -2.778,-2.778c-1.533,0 -2.778,1.245 -2.777,2.778l0,11.665Zm36.363,40.404c-5.774,1.271 -10.1,6.422 -10.1,12.577c0,6.153 4.325,11.305 10.1,12.577l0,11.664c0,1.533 1.245,2.778 2.778,2.778c1.533,-0 2.777,-1.245 2.777,-2.778l0,-11.664c5.775,-1.272 10.1,-6.424 10.1,-12.577c0,-6.154 -4.325,-11.305 -10.099,-12.577l0,-52.069c0,-1.533 -1.245,-2.778 -2.778,-2.778c-1.533,-0 -2.778,1.245 -2.778,2.778l0,52.069Zm2.778,5.255c4.041,-0 7.322,3.28 7.322,7.322c0,4.041 -3.281,7.322 -7.322,7.322c-4.042,-0 -7.323,-3.281 -7.323,-7.322c0,-4.042 3.281,-7.322 7.323,-7.322Zm-72.728,-16.162c4.042,-0 7.323,3.281 7.323,7.323c0,4.041 -3.281,7.322 -7.323,7.322c-4.041,-0 -7.322,-3.281 -7.322,-7.322c0,-4.042 3.281,-7.323 7.322,-7.323Zm36.364,-24.242c4.042,-0 7.322,3.281 7.322,7.322c0,4.041 -3.28,7.322 -7.322,7.322c-4.041,-0 -7.322,-3.281 -7.322,-7.322c0,-4.041 3.281,-7.322 7.322,-7.322Z"
    />
  );
}
