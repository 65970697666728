import React from 'react';
import Svg from './Svg';

export default function Events(props) {
  return (
    <Svg
      {...props}
      d="M60.56,25.87l-29.718,0c-1.742,0 -3.418,0.694 -4.662,1.928l-15.469,15.469c-2.579,2.579 -2.579,6.76 0,9.339c0.919,0.919 2.094,1.544 3.367,1.803l19.217,3.841l20.453,20.453l3.842,19.217c0.258,1.273 0.883,2.449 1.802,3.367c2.579,2.58 6.76,2.58 9.34,0l15.468,-15.468c1.235,-1.244 1.928,-2.921 1.928,-4.662l0,-29.722l4.596,-4.595c13.251,-13.251 13.017,-26.542 12.172,-32.197l0,-0.005c-0.433,-2.863 -2.683,-5.11 -5.546,-5.533c-5.651,-0.844 -18.946,-1.08 -32.193,12.167l-4.597,4.598Zm-23.118,55.991c-1.894,5.678 -6.097,9.047 -10.412,11.091c-4.079,1.932 -8.285,2.712 -11.008,3.026c0.314,-2.724 1.093,-6.931 3.026,-11.011c2.044,-4.314 5.413,-8.517 11.09,-10.408c1.455,-0.485 2.242,-2.059 1.758,-3.514c-0.485,-1.454 -2.059,-2.242 -3.514,-1.757c-7.305,2.433 -11.725,7.749 -14.355,13.301c-3.779,7.978 -3.772,16.379 -3.772,16.379c0,1.534 1.243,2.777 2.777,2.777c0,0 8.399,0.007 16.377,-3.772c5.551,-2.63 10.867,-7.05 13.303,-14.354c0.485,-1.455 -0.302,-3.029 -1.756,-3.514c-1.455,-0.485 -3.029,0.302 -3.514,1.756Zm43.131,-24.87l-21.251,21.251l3.712,18.574c0.042,0.205 0.139,0.395 0.286,0.543c0.41,0.41 1.074,0.41 1.483,0c0,0 15.455,-15.455 15.459,-15.459c0.196,-0.2 0.311,-0.466 0.311,-0.743l0,-24.166Zm-16.972,-26.306l-25.007,25.007l17.711,17.711l30.491,-30.492c11.325,-11.324 11.328,-22.608 10.606,-27.444c-0.068,-0.447 -0.417,-0.8 -0.864,-0.866c-0.002,-0 -0.004,-0 -0.005,-0.001c-4.829,-0.722 -16.12,-0.727 -27.448,10.601l-5.334,5.334c-0.048,0.052 -0.098,0.102 -0.15,0.15Zm-8.597,0.74l-24.162,-0c-0.28,-0 -0.547,0.117 -0.748,0.315l-15.455,15.455c-0.409,0.41 -0.409,1.073 0,1.483c0.148,0.148 0.339,0.244 0.543,0.286l18.571,3.712l21.251,-21.251Z"
    />
  );
}
