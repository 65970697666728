import React from 'react';
import Svg from './Svg';

export default function MaterialIconFormatListNumbered(props) {
  return (
    <Svg
      {...props}
      d="M20,88l-0,-3.4l8.4,-0l-0,-2.6l-4.2,-0l-0,-3.4l4.2,-0l-0,-2.6l-8.4,-0l-0,-3.4l11.8,-0l-0,15.4l-11.8,-0Zm20.9,-4.9l-0,-6l51.1,-0l-0,6l-51.1,-0Zm-20.9,-19.4l-0,-3.2l7.5,-8.8l-7.5,0l-0,-3.4l11.8,0l-0,3.2l-7.6,8.8l7.6,0l0,3.4l-11.8,-0Zm20.9,-4.9l-0,-6l51.1,0l-0,6l-51.1,-0Zm-16.7,-19.2l-0,-12.2l-4.2,0l-0,-3.4l7.6,0l-0,15.6l-3.4,0Zm16.7,-5.1l-0,-6l51.1,0l-0,6l-51.1,0Z"
    />
  );
}
