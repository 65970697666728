import React from 'react';
import Svg from './Svg';

export default function Add(props) {
  return (
    <Svg
      {...props}
      d="M89.392,22.608c-18.43,-18.429 -48.354,-18.429 -66.784,0c-18.429,18.43 -18.429,48.354 0,66.784c18.43,18.429 48.354,18.429 66.784,0c18.429,-18.43 18.429,-48.354 0,-66.784Zm-3.928,3.928c16.261,16.262 16.261,42.666 0,58.928c-16.262,16.261 -42.666,16.261 -58.928,-0c-16.261,-16.262 -16.261,-42.666 0,-58.928c16.262,-16.261 42.666,-16.261 58.928,-0Zm-32.437,24.834l-18.171,0c-1.534,0 -2.778,1.245 -2.778,2.778c0,1.533 1.244,2.778 2.778,2.778l18.171,0l0,20.025c0,1.533 1.244,2.777 2.777,2.777c1.534,-0 2.778,-1.244 2.778,-2.777l0,-20.025l18.174,-0c1.533,-0 2.777,-1.245 2.777,-2.778c0,-1.533 -1.244,-2.778 -2.777,-2.778l-18.174,-0l0,-16.319c0,-1.534 -1.244,-2.778 -2.778,-2.778c-1.533,0 -2.777,1.244 -2.777,2.778l0,16.319Z"
    />
  );
}
