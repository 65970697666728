import React from 'react';
import Svg from './Svg';

export default function MaterialIconFileUpload(props) {
  return (
    <Svg
      {...props}
      d="M42,74.667l28,-0l-0,-28l18.667,-0l-32.667,-32.667l-32.667,32.667l18.667,-0l-0,28Zm-18.667,9.333l65.334,-0l-0,9.333l-65.334,0l0,-9.333Z"
    />
  );
}
