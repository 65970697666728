import React from 'react';
import Svg from './Svg';

export default function MaterialIconLink(props) {
  return (
    <Svg
      {...props}
      d="M53,76l-17,0c-5.534,0 -10.25,-1.95 -14.15,-5.85c-3.9,-3.9 -5.85,-8.616 -5.85,-14.15c0,-5.534 1.95,-10.25 5.85,-14.15c3.9,-3.9 8.616,-5.85 14.15,-5.85l17,-0l0,6l-17,-0c-3.866,-0 -7.166,1.366 -9.9,4.1c-2.734,2.734 -4.1,6.034 -4.1,9.9c0,3.866 1.366,7.166 4.1,9.9c2.734,2.734 6.034,4.1 9.9,4.1l17,0l0,6Zm-12.5,-17l0,-6l31,0l0,6l-31,0Zm18.5,17l0,-6l17,0c3.866,0 7.166,-1.366 9.9,-4.1c2.734,-2.734 4.1,-6.034 4.1,-9.9c0,-3.866 -1.366,-7.166 -4.1,-9.9c-2.734,-2.734 -6.034,-4.1 -9.9,-4.1l-17,0l0,-6l17,0c5.534,0 10.25,1.95 14.15,5.85c3.9,3.9 5.85,8.616 5.85,14.15c0,5.534 -1.95,10.25 -5.85,14.15c-3.9,3.9 -8.616,5.85 -14.15,5.85l-17,0Z"
    />
  );
}
