import React from 'react';
import Svg from './Svg';

export default function Edit(props) {
  return (
    <Svg
      {...props}
      d="M9.725,71.545c-0.054,0.117 -0.098,0.237 -0.134,0.358c-0.236,0.694 -0.36,1.428 -0.36,2.175l-0,21.984c-0,3.706 3.005,6.711 6.711,6.711c0,-0 21.983,-0 21.983,-0c1.779,-0 3.482,-0.708 4.741,-1.956l46.012,-46.017c0.023,-0.022 0.047,-0.044 0.07,-0.067c0.023,-0.024 0.046,-0.047 0.068,-0.071l12.89,-12.892c2.623,-2.613 2.626,-6.869 0,-9.495l-21.987,-21.987c-2.623,-2.613 -6.865,-2.613 -9.488,0l-59.037,59.043c-0.638,0.634 -1.135,1.39 -1.469,2.214Zm5.062,7.609l18.063,18.063l-16.908,0c-0.638,0 -1.155,-0.517 -1.155,-1.155l-0,-16.908Zm1.149,-6.708l23.621,23.622l43.297,-43.301l-23.621,-23.622l-43.297,43.301Zm70.846,-23.607l-23.621,-23.622l10.992,-10.994c0.455,-0.452 1.19,-0.452 1.645,0c-0,0 21.98,21.981 21.98,21.981c0.452,0.452 0.455,1.184 0.003,1.634l-10.999,11.001Z"
    />
  );
}
