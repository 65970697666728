import React from 'react';
import Svg from './Svg';

export default function MaterialIconFormatAlignJustify(props) {
  return (
    <Svg
      {...props}
      d="M20,92l-0,-6l72,-0l-0,6l-72,-0Zm-0,-16.5l-0,-6l72,-0l-0,6l-72,-0Zm-0,-16.5l-0,-6l72,-0l-0,6l-72,-0Zm-0,-16.5l-0,-6l72,-0l-0,6l-72,-0Zm-0,-16.5l-0,-6l72,-0l-0,6l-72,-0Z"
    />
  );
}
