import React from 'react';
import Svg from './Svg';

export default function ArrowUp(props) {
  return (
    <Svg
      {...props}
      d="M102.981,78.508l-46.981,-46.981l-46.981,46.981l3.929,3.929l43.052,-43.053c0,0 43.052,43.053 43.052,43.053l3.929,-3.929Z"
    />
  );
}
