import React from 'react';
import Svg from './Svg';

export default function ArrowRight(props) {
  return (
    <Svg
      {...props}
      d="M34.474,103.963l46.981,-46.981l-46.981,-46.981l-3.929,3.929l43.053,43.052c-0,0 -43.053,43.053 -43.053,43.053l3.929,3.928Z"
    />
  );
}
