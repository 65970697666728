import React from 'react';
import Svg from './Svg';

export default function MaterialIconCode(props) {
  return (
    <Svg
      {...props}
      d="M40,80l-24,-24l24.2,-24.2l4.3,4.3l-19.9,19.9l19.7,19.7l-4.3,4.3Zm31.8,0.2l-4.3,-4.3l19.9,-19.9l-19.7,-19.7l4.3,-4.3l24,24l-24.2,24.2Z"
    />
  );
}
