import React from 'react';
import Svg from './Svg';

export default function MaterialIconFormatBold(props) {
  return (
    <Svg
      {...props}
      d="M35.75,82l0,-56l22.8,-0c4.4,-0 8.217,1.4 11.45,4.2c3.233,2.8 4.85,6.333 4.85,10.6c-0,2.533 -0.7,4.867 -2.1,7c-1.4,2.133 -3.267,3.767 -5.6,4.9l-0,0.6c2.867,0.933 5.183,2.6 6.95,5c1.767,2.4 2.65,5.1 2.65,8.1c-0,4.533 -1.75,8.267 -5.25,11.2c-3.5,2.933 -7.583,4.4 -12.25,4.4l-23.5,-0Zm8.6,-32.3l13.6,-0c2.333,-0 4.35,-0.767 6.05,-2.3c1.7,-1.533 2.55,-3.467 2.55,-5.8c-0,-2.333 -0.85,-4.283 -2.55,-5.85c-1.7,-1.567 -3.717,-2.35 -6.05,-2.35l-13.6,-0l-0,16.3Zm-0,24.7l14.4,-0c2.533,-0 4.733,-0.833 6.6,-2.5c1.867,-1.667 2.8,-3.767 2.8,-6.3c-0,-2.467 -0.933,-4.533 -2.8,-6.2c-1.867,-1.667 -4.067,-2.5 -6.6,-2.5l-14.4,-0l-0,17.5Z"
    />
  );
}
