import React from 'react';
import Svg from './Svg';

export default function Tasks(props) {
  return (
    <Svg
      {...props}
      d="M35.444,15.889l-11.444,-0c-3.493,-0 -6.333,2.83 -6.333,6.333l0,74.667c0,3.493 2.84,6.333 6.333,6.333l64,0c3.493,0 6.333,-2.84 6.333,-6.333l0,-74.667c0,-3.503 -2.84,-6.333 -6.333,-6.333l-11.445,0l0,-4.333c0,-1.534 -1.244,-2.778 -2.778,-2.778l-35.555,0c-1.534,0 -2.778,1.244 -2.778,2.778l0,4.333Zm41.111,5.555l0,7.89c0,1.534 -1.244,2.777 -2.778,2.777l-35.555,-0c-1.534,-0 -2.778,-1.243 -2.778,-2.777l0,-7.89l-11.444,-0c-0.429,-0 -0.778,0.348 -0.778,0.778l0,74.667c0,0.429 0.349,0.777 0.778,0.777l64,-0c0.429,-0 0.778,-0.348 0.778,-0.777l0,-74.667c0,-0.43 -0.349,-0.778 -0.778,-0.778l-11.445,-0Zm-34.777,49.777l28.444,0c1.533,0 2.778,-1.244 2.778,-2.777c0,-1.533 -1.245,-2.778 -2.778,-2.778l-28.444,0c-1.533,0 -2.778,1.245 -2.778,2.778c0,1.533 1.245,2.777 2.778,2.777Zm0,-14.222l28.444,0c1.533,0 2.778,-1.245 2.778,-2.778c0,-1.533 -1.245,-2.777 -2.778,-2.777l-28.444,0c-1.533,0 -2.778,1.244 -2.778,2.777c0,1.533 1.245,2.778 2.778,2.778Zm-0.779,-38.388c0.001,0.018 0.001,0.037 0.001,0.055c0,0.019 0,0.037 -0.001,0.056l0,7.834l30,0l0,-12.222l-30,0l0,4.277Z"
    />
  );
}
