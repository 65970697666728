import React from 'react';
import Svg from './Svg';

export default function Reminder(props) {
  return (
    <Svg
      {...props}
      d="M40.318,90.053c1.316,7.483 7.851,13.168 15.709,13.168c7.86,0 14.391,-5.686 15.704,-13.168l16.906,0c1.106,0 2.182,-0.302 3.133,-0.876c0.005,-0.003 0.01,-0.006 0.015,-0.009c2.861,-1.747 3.77,-5.474 2.032,-8.337c-2.471,-4.277 -5.19,-12.008 -5.374,-25.078c-0.237,-16.728 -12.032,-31.713 -28.657,-33.593c-1.243,-0.145 -2.493,-0.218 -3.749,-0.218c-17.9,0 -32.416,14.511 -32.416,32.411c0,14.089 -2.893,22.242 -5.498,26.655c-0.001,0.002 -0.002,0.003 -0.003,0.005c-2.347,3.994 0.647,9.04 5.29,9.04l16.908,-0Zm25.723,0l-20.033,0c1.216,4.39 5.243,7.613 10.019,7.613c4.777,0 8.8,-3.223 10.014,-7.613Zm16.847,-34.222c0.204,14.52 3.388,23.064 6.146,27.826c0.01,0.018 0.021,0.037 0.033,0.055c0.147,0.241 0.071,0.553 -0.164,0.707c-0.085,0.05 -0.174,0.079 -0.266,0.079l-65.227,0c-0.371,0 -0.687,-0.352 -0.5,-0.671c2.882,-4.882 6.266,-13.873 6.266,-29.474c0,-14.832 12.029,-26.855 26.861,-26.855c1.042,0 2.079,0.06 3.111,0.181c0.003,0 0.007,0.001 0.011,0.001c13.863,1.566 23.532,14.203 23.729,28.151Zm-36.742,-41.497l19.756,0c1.533,0 2.778,-1.244 2.778,-2.777c0,-1.533 -1.245,-2.778 -2.778,-2.778l-19.756,0c-1.533,0 -2.778,1.245 -2.778,2.778c0,1.533 1.245,2.777 2.778,2.777Z"
    />
  );
}
