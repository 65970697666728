import React from 'react';
import Svg from './Svg';

export default function Disconnect(props) {
  return (
    <Svg
      {...props}
      d="M86.817,85.464c-16.271,16.27 -42.656,16.27 -58.927,0c-16.271,-16.271 -16.271,-42.657 -0,-58.928c16.271,-16.27 42.656,-16.27 58.927,0c1.084,1.084 2.845,1.084 3.929,0c1.084,-1.084 1.084,-2.844 -0,-3.928c-18.441,-18.44 -48.344,-18.44 -66.784,0c-18.441,18.44 -18.441,48.344 -0,66.784c18.44,18.44 48.343,18.44 66.784,0c1.084,-1.084 1.084,-2.844 -0,-3.928c-1.084,-1.084 -2.845,-1.084 -3.929,0Zm5.563,-32.241l-30.328,0c-1.533,0 -2.778,1.244 -2.778,2.778c-0,1.533 1.245,2.777 2.778,2.777l30.332,0l-10.972,10.972c-1.084,1.084 -1.084,2.845 -0,3.929c1.084,1.084 2.844,1.084 3.928,0l15.689,-15.689c0.518,-0.505 0.84,-1.21 0.84,-1.989c-0,-0.822 -0.358,-1.56 -0.925,-2.069l-15.604,-15.609c-1.084,-1.084 -2.844,-1.085 -3.928,-0.001c-1.084,1.084 -1.085,2.844 -0.001,3.929l10.969,10.972Z"
    />
  );
}
