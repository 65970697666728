import React from 'react';
import Svg from './Svg';

export default function Account(props) {
  return (
    <Svg
      {...props}
      d="M24.248,90.942c0.107,0.114 0.225,0.221 0.354,0.318c8.348,7.44 19.349,11.962 31.398,11.962c12.054,-0 23.059,-4.526 31.405,-11.968c0.122,-0.093 0.236,-0.195 0.339,-0.305c9.505,-8.639 15.478,-21.102 15.478,-34.949c0,-26.063 -21.159,-47.222 -47.222,-47.222c-26.063,-0 -47.222,21.159 -47.222,47.222c0,13.842 5.968,26.301 15.47,34.942Zm32.234,-17.353c-0.16,0.004 -0.321,0.005 -0.482,0.005c-0.154,0 -0.308,-0.001 -0.462,-0.005c-10.28,0.164 -20.155,5.523 -25.73,14.808c7.16,5.797 16.274,9.27 26.192,9.27c9.918,0 19.032,-3.473 26.19,-9.268c-2.946,-4.894 -7.212,-8.881 -12.333,-11.485c-4.295,-2.184 -8.863,-3.257 -13.375,-3.325Zm29.854,10.962c7.024,-7.459 11.331,-17.506 11.331,-28.551c0,-22.996 -18.671,-41.667 -41.667,-41.667c-22.996,0 -41.667,18.671 -41.667,41.667c0,11.043 4.306,21.088 11.328,28.548c4.499,-6.978 11.104,-11.96 18.533,-14.528c-5.719,-3.821 -9.488,-10.335 -9.488,-17.723c0,-11.754 9.541,-21.297 21.294,-21.297c11.753,0 21.294,9.543 21.294,21.297c0,7.387 -3.767,13.9 -9.484,17.721c1.542,0.535 3.068,1.183 4.565,1.944c5.705,2.901 10.513,7.255 13.961,12.589Zm-29.856,-16.519c8.465,-0.255 15.259,-7.208 15.259,-15.735c0,-8.688 -7.052,-15.741 -15.739,-15.741c-8.687,0 -15.739,7.053 -15.739,15.741c0,8.533 6.802,15.489 15.275,15.735c0.314,-0.004 0.628,-0.004 0.944,0Z"
    />
  );
}
