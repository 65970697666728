import React from 'react';
import Svg from './Svg';

export default function Print(props) {
  return (
    <Svg
      {...props}
      d="M24.642,28.019l-5.873,-0c-5.765,-0 -10.341,4.414 -10.341,9.738l0,36.03c0,1.399 1.134,2.534 2.533,2.534l13.682,0l0,17.28c0,1.399 1.134,2.533 2.534,2.533l57.649,0c1.4,0 2.534,-1.134 2.534,-2.533l0,-17.28l13.679,0c1.399,0 2.533,-1.135 2.533,-2.534l0,-36.03c0,-5.324 -4.576,-9.738 -10.341,-9.738l-5.873,0l0,-15.486c0,-1.399 -1.134,-2.533 -2.533,-2.533l-57.65,0c-1.399,0 -2.533,1.134 -2.533,2.533l0,15.486Zm57.651,45.681l0,-8.189l-52.583,-0l0,25.556l52.583,-0l0,-17.193c-0.001,-0.028 -0.001,-0.058 -0.001,-0.087c0,-0.029 0,-0.058 0.001,-0.087Zm5.067,-2.446l11.145,-0l0,-33.497c0,-2.641 -2.415,-4.671 -5.274,-4.671l-74.462,-0c-2.859,-0 -5.274,2.03 -5.274,4.671l0,33.497l11.148,-0l0,-8.277c0,-1.399 1.134,-2.533 2.534,-2.533l57.649,-0c1.4,-0 2.534,1.134 2.534,2.533l0,8.277Zm-57.652,-43.235l52.584,-0l0,-12.952l-52.584,-0l0,12.952Z"
    />
  );
}
