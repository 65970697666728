import React from 'react';
import Svg from './Svg';

export default function MaterialIconFormatItalic(props) {
  return (
    <Svg
      {...props}
      d="M28,88l0,-10l13.7,-0l17.8,-44l-15.5,-0l0,-10l40,-0l-0,10l-13.7,-0l-17.8,44l15.5,-0l-0,10l-40,-0Z"
    />
  );
}
