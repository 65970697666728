import React from 'react';
import Svg from './Svg';

export default function MoreContacts(props) {
  return (
    <Svg
      {...props}
      d="M28.886,48.025c-6.56,2.304 -12.483,6.51 -16.801,12.453c-0.344,0.475 -0.53,1.046 -0.53,1.633l0,38.333c0,1.533 1.245,2.778 2.778,2.778c1.533,0 2.778,-1.245 2.778,-2.778l0,-37.408c5.712,-7.384 14.441,-11.381 23.357,-11.521c0.157,0.003 0.314,0.005 0.471,0.005c0.162,0 0.323,-0.002 0.482,-0.005c5.774,0.087 11.582,1.794 16.66,5.248c2.555,1.74 4.81,3.86 6.685,6.275l0,37.406c0,1.533 1.245,2.778 2.778,2.778c1.533,0 2.778,-1.245 2.778,-2.778l0,-38.333c0,-0.588 -0.187,-1.161 -0.533,-1.636c-2.348,-3.222 -5.25,-6.035 -8.581,-8.304c-0.001,-0.001 -0.001,-0.001 -0.002,-0.001c-2.598,-1.768 -5.358,-3.147 -8.204,-4.152c5.985,-3.824 9.931,-10.409 9.931,-17.865c0,-11.767 -9.83,-21.367 -21.994,-21.367c-12.164,0 -21.995,9.6 -21.995,21.367c0,7.459 3.951,14.047 9.942,17.872Zm42.363,-2.059l-0.189,0c-0.527,0 -1.02,0.148 -1.44,0.403c-0.802,0.487 -1.339,1.37 -1.339,2.376c0,1.533 1.245,2.778 2.778,2.778c0.069,0 0.139,0 0.208,-0.001c9.313,0.056 18.054,4.321 23.622,11.513l0,37.409c0,1.533 1.245,2.777 2.778,2.777c1.533,0 2.778,-1.244 2.778,-2.777l0,-38.334c0,-0.586 -0.186,-1.158 -0.53,-1.632c-4.206,-5.79 -10.097,-10.093 -16.786,-12.453c7.831,-4.982 11.77,-14.49 9.091,-23.69c-3.293,-11.31 -15.411,-17.944 -27.102,-14.759c-1.48,0.403 -2.353,1.931 -1.95,3.411c0.403,1.479 1.931,2.353 3.41,1.95c8.741,-2.382 17.846,2.495 20.308,10.952c2.448,8.405 -2.657,17.109 -11.346,19.476c-1.396,0.38 -2.84,0.585 -4.291,0.601Zm-30.31,-31.624c9.048,0 16.439,7.059 16.439,15.811c0,8.752 -7.391,15.811 -16.439,15.811c-9.048,0 -16.439,-7.059 -16.439,-15.811c0,-8.752 7.391,-15.811 16.439,-15.811Z"
    />
  );
}
