import React from 'react';
import Svg from './Svg';

export default function ExternalLinks(props) {
  return (
    <Svg
      {...props}
      d="M97.03,63.786l0,33.195c0,0.695 -0.564,1.261 -1.261,1.261l-80.75,0c-0.696,0 -1.261,-0.566 -1.261,-1.261l0,-80.75c0,-0.696 0.565,-1.262 1.261,-1.262l32.978,0c1.533,0 2.777,-1.244 2.777,-2.777c0,-1.533 -1.244,-2.778 -2.777,-2.778l-32.978,0c-3.76,0 -6.817,3.057 -6.817,6.817l0,80.75c0,3.76 3.057,6.816 6.817,6.816l80.75,0c3.769,0 6.817,-3.056 6.817,-6.816l0,-33.195c0,-1.533 -1.245,-2.778 -2.778,-2.778c-1.533,0 -2.778,1.245 -2.778,2.778Zm-3.865,-48.879l-23.521,23.521c-1.084,1.084 -1.084,2.844 0,3.928c1.084,1.084 2.844,1.084 3.929,0l23.523,-23.523l0,17.524c0,1.533 1.245,2.778 2.778,2.778c1.533,0 2.778,-1.245 2.778,-2.778l0,-24.228c0,-1.534 -1.244,-2.777 -2.778,-2.777l-24.228,0c-1.533,0 -2.778,1.244 -2.778,2.777c0,1.534 1.245,2.778 2.778,2.778l17.519,0Z"
    />
  );
}
