import React from 'react';
import Svg from './Svg';

export default function Home(props) {
  return (
    <Svg
      {...props}
      d="M99.757,46.205l-39.252,-35.686c-2.552,-2.324 -6.463,-2.324 -9.017,0.002c0.002,-0.002 -39.243,35.681 -39.243,35.681c-0.001,0.001 -0.002,0.002 -0.002,0.003c-1.394,1.27 -2.19,3.072 -2.19,4.958l0,45.356c0,3.7 3,6.705 6.7,6.705l78.494,0c3.7,0 6.7,-3.005 6.7,-6.705l0,-45.356c0,-1.886 -0.796,-3.688 -2.19,-4.958Zm-3.742,4.106c0.24,0.218 0.377,0.528 0.377,0.852l0,45.356c0,0.633 -0.511,1.15 -1.145,1.15l-78.494,0c-0.634,0 -1.145,-0.517 -1.145,-1.15l0,-45.356c0,-0.324 0.137,-0.634 0.377,-0.852l39.244,-35.683c0.435,-0.396 1.101,-0.396 1.537,0l39.249,35.683Z"
    />
  );
}
