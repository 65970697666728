import React from 'react';
import Svg from './Svg';

export default function MaterialIconImage(props) {
  return (
    <Svg
      {...props}
      d="M26,92c-1.6,0 -3,-0.6 -4.2,-1.8c-1.2,-1.2 -1.8,-2.6 -1.8,-4.2l0,-60c0,-1.6 0.6,-3 1.8,-4.2c1.2,-1.2 2.6,-1.8 4.2,-1.8l60,0c1.6,0 3,0.6 4.2,1.8c1.2,1.2 1.8,2.6 1.8,4.2l0,60c0,1.6 -0.6,3 -1.8,4.2c-1.2,1.2 -2.6,1.8 -4.2,1.8l-60,0Zm0,-6l60,0l0,-60l-60,0l0,60Zm5.6,-9.7l48.9,0l-14.7,-19.6l-13.2,17.1l-9.3,-12.7l-11.7,15.2Zm-5.6,9.7l0,-60l0,60Z"
    />
  );
}
