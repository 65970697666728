import React from 'react';
import Svg from './Svg';

export default function MailObject(props) {
  return (
    <Svg
      {...props}
      d="M93.878,8.778l-75.756,0c-3.58,0 -6.566,3.009 -6.566,6.816l0,80.812c0,3.807 2.987,6.816 6.566,6.816l75.756,0c3.579,0 6.566,-3.009 6.566,-6.816l0,-80.812c0,-3.807 -2.987,-6.816 -6.566,-6.816Zm-63.382,5.555l0,83.334l-12.374,-0c-0.611,-0 -1.011,-0.611 -1.011,-1.261l0,-80.812c0,-0.65 0.4,-1.261 1.011,-1.261l12.374,-0Zm5.556,-0l57.826,-0c0.611,-0 1.011,0.611 1.011,1.261l0,80.812c0,0.65 -0.4,1.261 -1.011,1.261l-57.826,-0l0,-83.334Zm12.374,52.525l30.305,0c1.533,0 2.778,-1.244 2.778,-2.777c0,-1.534 -1.245,-2.778 -2.778,-2.778l-30.305,0c-1.534,0 -2.778,1.244 -2.778,2.778c0,1.533 1.244,2.777 2.778,2.777Zm0,-16.161l30.305,0c1.533,0 2.778,-1.244 2.778,-2.778c0,-1.533 -1.245,-2.777 -2.778,-2.777l-30.305,0c-1.534,0 -2.778,1.244 -2.778,2.777c0,1.534 1.244,2.778 2.778,2.778Z"
    />
  );
}
