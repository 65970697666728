import React from 'react';
import Svg from './Svg';

export default function AddContact(props) {
  return (
    <Svg
      {...props}
      d="M23.835,90.575c0.259,0.423 0.634,0.781 1.108,1.022c0.022,0.011 0.044,0.022 0.067,0.033c10.283,8.934 24.335,13.371 38.82,10.934c8.994,-1.511 16.963,-5.472 23.34,-11.085c0.284,-0.176 0.526,-0.396 0.722,-0.648c11.392,-10.449 17.428,-26.279 14.672,-42.661c-0.254,-1.512 -1.688,-2.533 -3.2,-2.279c-1.512,0.255 -2.533,1.689 -2.278,3.2c2.251,13.38 -2.145,26.341 -10.785,35.509c-3.45,-5.33 -8.26,-9.675 -13.956,-12.571c-1.501,-0.764 -3.03,-1.413 -4.578,-1.95c5.728,-3.824 9.502,-10.345 9.502,-17.741c0,-11.763 -9.55,-21.314 -21.313,-21.314c-11.764,0 -21.314,9.551 -21.314,21.314c-0,7.396 3.775,13.917 9.502,17.74c-7.412,2.56 -14.003,7.518 -18.504,14.463c-5.453,-5.791 -9.309,-13.202 -10.726,-21.632c0,0 0,-0 0,-0c-3.817,-22.693 11.484,-44.182 34.177,-47.995c0,0 0.001,0 0.001,0c4.572,-0.771 9.244,-0.771 13.816,0c1.511,0.255 2.946,-0.766 3.201,-2.277c0.254,-1.512 -0.766,-2.946 -2.278,-3.201c-5.183,-0.874 -10.478,-0.874 -15.661,-0c-25.719,4.321 -43.061,28.676 -38.734,54.394c1.793,10.676 7.039,19.908 14.399,26.745Zm32.525,-16.927c-0.134,0.003 -0.269,0.004 -0.404,0.004c-0.13,0 -0.259,-0.001 -0.39,-0.003c-10.285,0.137 -20.176,5.472 -25.78,14.737c8.961,7.246 20.866,10.762 33.123,8.7c-0,-0 0,-0 0,-0c7.314,-1.229 13.86,-4.294 19.244,-8.642c-2.947,-4.888 -7.215,-8.865 -12.326,-11.463c-0.001,-0.001 -0.001,-0.001 -0.001,-0.001c-4.324,-2.2 -8.924,-3.274 -13.466,-3.332Zm0.011,-5.557c8.506,-0.221 15.343,-7.195 15.343,-15.753c-0,-8.697 -7.061,-15.758 -15.758,-15.758c-8.698,0 -15.759,7.061 -15.759,15.758c0,8.563 6.844,15.539 15.356,15.754c0.272,-0.004 0.545,-0.004 0.818,-0.001Zm30.17,-48.188l-8.344,-0c-1.533,-0 -2.778,1.244 -2.778,2.778c-0,1.533 1.245,2.777 2.778,2.777l8.344,0l-0,8.345c-0,1.533 1.244,2.778 2.777,2.778c1.533,0 2.778,-1.245 2.778,-2.778l0,-8.345l8.345,0c1.533,0 2.778,-1.244 2.778,-2.777c-0,-1.534 -1.245,-2.778 -2.778,-2.778l-8.345,-0l0,-8.344c0,-1.533 -1.245,-2.778 -2.778,-2.778c-1.533,0 -2.777,1.245 -2.777,2.778l-0,8.344Z"
    />
  );
}
