import React from 'react';
import Account from "./icons/Account";
import Add from "./icons/Add";
import AddContact from "./icons/AddContact";
import ArrowDown from "./icons/ArrowDown";
import ArrowLeft from "./icons/ArrowLeft";
import ArrowRight from "./icons/ArrowRight";
import ArrowUp from "./icons/ArrowUp";
import Calendar from "./icons/Calendar";
import CheckedBox from "./icons/CheckedBox";
import Comments from "./icons/Comments";
import Contact from "./icons/Contact";
import Contracts from "./icons/Contracts";
import Disconnect from "./icons/Disconnect";
import Documents from "./icons/Documents";
import Edit from "./icons/Edit";
import Events from "./icons/Events";
import ExternalLinks from "./icons/ExternalLinks";
import Filter from "./icons/Filter";
import Graph from "./icons/Graph";
import Home from "./icons/Home";
import MapPin from "./icons/MapPin";

import MaterialIconCode from "./icons/MaterialIconCode";
import MaterialIconDelete from "./icons/MaterialIconDelete";
import MaterialIconFileUpload from "./icons/MaterialIconFileUpload";
import MaterialIconFormatAlignCenter from "./icons/MaterialIconFormatAlignCenter";
import MaterialIconFormatAlignJustify from "./icons/MaterialIconFormatAlignJustify";
import MaterialIconFormatAlignLeft from "./icons/MaterialIconFormatAlignLeft";
import MaterialIconFormatAlignRight from "./icons/MaterialIconFormatAlignRight";
import MaterialIconFormatBold from "./icons/MaterialIconFormatBold";
import MaterialIconFormatItalic from "./icons/MaterialIconFormatItalic";
import MaterialIconFormatListBulleted from "./icons/MaterialIconFormatListBulleted";
import MaterialIconFormatListNumbered from "./icons/MaterialIconFormatListNumbered";
import MaterialIconFormatQuote from "./icons/MaterialIconFormatQuote";
import MaterialIconFormatUnderlined from "./icons/MaterialIconFormatUnderlined";
import MaterialIconImage from "./icons/MaterialIconImage";
import MaterialIconLink from "./icons/MaterialIconLink";
import MaterialIconLinkOff from "./icons/MaterialIconLinkOff";
import MaterialIconLooksOne from "./icons/MaterialIconLooksOne";
import MaterialIconLooksTwo from "./icons/MaterialIconLooksTwo";
import MaterialIconUndo from "./icons/MaterialIconUndo";

import Message from "./icons/Message";
import MoreContacts from "./icons/MoreContacts";
import MailObject from "./icons/MailObject";
import Payment from "./icons/Payment";
import Phone from "./icons/Phone";
import Print from "./icons/Print";
import Reminder from "./icons/Reminder";
import Remove from "./icons/Remove";
import Search from "./icons/Search";
import Swipe from "./icons/Swipe";
import Tasks from "./icons/Tasks";

export const Icon = (props) => (
  <>
  {
    props.iconName === "account" ? <Account {...props} />
    : props.iconName === "add" ? <Add {...props} />
    : props.iconName === "add-contact" ? <AddContact {...props} />
    : props.iconName === "arrow-down" ? <ArrowDown {...props} />
    : props.iconName === "arrow-left" ? <ArrowLeft {...props} />
    : props.iconName === "arrow-right" ? <ArrowRight {...props} />
    : props.iconName === "arrow-up" ? <ArrowUp {...props} />
    : props.iconName === "calendar" ? <Calendar {...props} />
    : props.iconName === "checked-box" ? <CheckedBox {...props} />
    : props.iconName === "comments" ? <Comments {...props} />
    : props.iconName === "contact" ? <Contact {...props} />
    : props.iconName === "contracts" ? <Contracts {...props} />
    : props.iconName === "disconnect" ? <Disconnect {...props} />
    : props.iconName === "documents" ? <Documents {...props} />
    : props.iconName === "edit" ? <Edit {...props} />
    : props.iconName === "events" ? <Events {...props} />
    : props.iconName === "external-links" ? <ExternalLinks {...props} />
    : props.iconName === "filter" ? <Filter {...props} />
    : props.iconName === "graph" ? <Graph {...props} />
    : props.iconName === "home" ? <Home {...props} />
    : props.iconName === "map-pin" ? <MapPin {...props} />
    : props.iconName === "material-icon-code" ? <MaterialIconCode {...props} />
    : props.iconName === "material-icon-delete" ? <MaterialIconDelete {...props} />
    : props.iconName === "material-icon-file-upload" ? <MaterialIconFileUpload {...props} />
    : props.iconName === "material-icon-format-align-center" ? <MaterialIconFormatAlignCenter {...props} />
    : props.iconName === "material-icon-format-align-justify" ? <MaterialIconFormatAlignJustify {...props} />
    : props.iconName === "material-icon-format-align-left" ? <MaterialIconFormatAlignLeft {...props} />
    : props.iconName === "material-icon-format-align-right" ? <MaterialIconFormatAlignRight {...props} />
    : props.iconName === "material-icon-format-bold" ? <MaterialIconFormatBold {...props} />
    : props.iconName === "material-icon-format-italic" ? <MaterialIconFormatItalic {...props} />
    : props.iconName === "material-icon-format-list-bulleted" ? <MaterialIconFormatListBulleted {...props} />
    : props.iconName === "material-icon-format-list-numbered" ? <MaterialIconFormatListNumbered {...props} />
    : props.iconName === "material-icon-format-quote" ? <MaterialIconFormatQuote {...props} />
    : props.iconName === "material-icon-format-underlined" ? <MaterialIconFormatUnderlined {...props} />
    : props.iconName === "material-icon-image" ? <MaterialIconImage {...props} />
    : props.iconName === "material-icon-link" ? <MaterialIconLink {...props} />
    : props.iconName === "material-icon-link-off" ? <MaterialIconLinkOff {...props} />
    : props.iconName === "material-icon-looks-one" ? <MaterialIconLooksOne {...props} />
    : props.iconName === "material-icon-looks-two" ? <MaterialIconLooksTwo {...props} />
    : props.iconName === "material-icon-undo" ? <MaterialIconUndo {...props} />
    : props.iconName === "message" ? <Message {...props} />
    : props.iconName === "more-contacts" ? <MoreContacts {...props} />
    : props.iconName === "mail-object" ? <MailObject {...props} />
    : props.iconName === "payment" ? <Payment {...props} />
    : props.iconName === "phone" ? <Phone {...props} />
    : props.iconName === "print" ? <Print {...props} />
    : props.iconName === "reminder" ? <Reminder {...props} />
    : props.iconName === "remove" ? <Remove {...props} />
    : props.iconName === "search" ? <Search {...props} />
    : props.iconName === "swipe" ? <Swipe {...props} />
    : props.iconName === "tasks" ? <Tasks {...props} />
    : null
  }
  </>
)