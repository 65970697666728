import React from 'react';
import Svg from './Svg';

export default function MaterialIconFormatAlignRight(props) {
  return (
    <Svg
      {...props}
      d="M20,92l0,-6l72,0l0,6l-72,0Zm24.9,-16.5l0,-6l47.1,0l0,6l-47.1,0Zm-24.9,-16.5l0,-6l72,0l0,6l-72,0Zm24.9,-16.5l0,-6l47.1,0l0,6l-47.1,0Zm-24.9,-16.5l0,-6l72,0l0,6l-72,0Z"
    />
  );
}
