import React from 'react';
import Svg from './Svg';

export default function Contracts(props) {
  return (
    <Svg
      {...props}
      d="M67.146,8.778l-44.485,0c-3.578,0 -6.478,2.905 -6.478,6.483l0,81.478c0,3.588 2.9,6.483 6.478,6.483l66.672,-0c3.577,-0 6.484,-2.896 6.484,-6.483l0,-59.256l-0.001,-0.034c-0.006,-0.502 -0.145,-0.973 -0.385,-1.377c-0.117,-0.2 -0.261,-0.386 -0.428,-0.553l-25.928,-25.928c-0.167,-0.167 -0.353,-0.31 -0.553,-0.428c-0.404,-0.239 -0.874,-0.379 -1.376,-0.385Zm-2.813,5.555l-41.672,-0c-0.511,-0 -0.922,0.417 -0.922,0.928l0,81.478c0,0.512 0.411,0.928 0.922,0.928l66.672,-0c0.512,-0 0.928,-0.415 0.928,-0.928l0,-56.478l-23.15,-0c-1.534,-0 -2.778,-1.244 -2.778,-2.778l0,-23.15Zm-23.148,62.964l29.628,-0c1.533,-0 2.778,-1.245 2.778,-2.778c0,-1.533 -1.245,-2.778 -2.778,-2.778l-29.628,-0c-1.533,-0 -2.778,1.245 -2.778,2.778c0,1.533 1.245,2.778 2.778,2.778Zm0,-14.815l29.628,-0c1.533,-0 2.778,-1.245 2.778,-2.778c0,-1.533 -1.245,-2.778 -2.778,-2.778l-29.628,-0c-1.533,-0 -2.778,1.245 -2.778,2.778c0,1.533 1.245,2.778 2.778,2.778Zm28.704,-27.776l16.444,-0l-16.444,-16.444l0,16.444Z"
    />
  );
}
