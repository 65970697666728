import React from 'react';
import Svg from './Svg';

export default function Search(props) {
  return (
    <Svg
      {...props}
      d="M73.23,76.908l22.931,22.932l3.679,-3.679l-22.931,-22.932c12.595,-14.488 12.002,-36.491 -1.777,-50.27l0,-0c-14.398,-14.398 -37.776,-14.398 -52.174,-0c-14.398,14.398 -14.398,37.776 -0,52.174c13.78,13.78 35.787,14.371 50.272,1.775Zm-1.777,-50.27c12.368,12.367 12.368,32.449 0,44.816c-12.367,12.368 -32.449,12.368 -44.816,0c-12.367,-12.367 -12.367,-32.449 -0,-44.816c12.367,-12.368 32.449,-12.368 44.816,-0l0,-0Z"
    />
  );
}
