import React from 'react';
import Svg from './Svg';

export default function Remove(props) {
  return (
    <Svg
      {...props}
      d="M103.222,56c-0,-26.063 -21.159,-47.222 -47.222,-47.222c-26.063,0 -47.222,21.159 -47.222,47.222c-0,26.063 21.159,47.222 47.222,47.222c26.063,0 47.222,-21.159 47.222,-47.222Zm-5.555,-0c0,22.996 -18.671,41.667 -41.667,41.667c-22.996,0 -41.667,-18.671 -41.667,-41.667c0,-22.996 18.671,-41.667 41.667,-41.667c22.996,0 41.667,18.671 41.667,41.667Zm-45.595,0l-14.16,14.16c-1.084,1.084 -1.084,2.844 0,3.928c1.084,1.084 2.844,1.084 3.928,0l14.16,-14.16l12.851,12.851c1.084,1.084 2.844,1.084 3.928,0c1.084,-1.084 1.084,-2.845 0,-3.929l-12.85,-12.85l11.539,-11.54c1.084,-1.084 1.084,-2.844 0,-3.928c-1.084,-1.084 -2.844,-1.084 -3.928,0l-11.54,11.539l-12.849,-12.849c-1.084,-1.084 -2.844,-1.084 -3.928,0c-1.084,1.085 -1.084,2.845 0,3.929l12.849,12.849Z"
    />
  );
}
