import React from 'react';
import Svg from './Svg';

export default function MaterialIconFormatQuote(props) {
  return (
    <Svg
      {...props}
      d="M66,55l16,-0l-0,-16l-16,-0l-0,16Zm-36,-0l16,-0l-0,-16l-16,-0l-0,16Zm40.6,22l8,-16l-18.6,-0l-0,-28l28,-0l-0,28.8l-7.6,15.2l-9.8,-0Zm-36,-0l8,-16l-18.6,-0l-0,-28l28,-0l-0,28.8l-7.6,15.2l-9.8,-0Z"
    />
  );
}
