import React from 'react';
import Svg from './Svg';

export default function MaterialIconLooksOne(props) {
  return (
    <Svg
      {...props}
      d="M57.2,76.3l6,-0l-0,-40.6l-14.3,-0l-0,6l8.3,-0l-0,34.6Zm-31.2,15.7c-1.6,-0 -3,-0.6 -4.2,-1.8c-1.2,-1.2 -1.8,-2.6 -1.8,-4.2l-0,-60c-0,-1.6 0.6,-3 1.8,-4.2c1.2,-1.2 2.6,-1.8 4.2,-1.8l60,-0c1.6,-0 3,0.6 4.2,1.8c1.2,1.2 1.8,2.6 1.8,4.2l-0,60c-0,1.6 -0.6,3 -1.8,4.2c-1.2,1.2 -2.6,1.8 -4.2,1.8l-60,-0Zm-0,-6l60,-0l-0,-60l-60,-0l-0,60Zm-0,-60l-0,60l-0,-60Z"
    />
  );
}
