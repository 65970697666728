import React from 'react';
import Svg from './Svg';

export default function ArrowDown(props) {
  return (
    <Svg
      {...props}
      d="M9.019,35.456l46.981,46.981l46.981,-46.981l-3.929,-3.929l-43.052,43.053c-0,-0 -43.052,-43.053 -43.052,-43.053l-3.929,3.929Z"
    />
  );
}
