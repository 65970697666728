import React from 'react';
import Svg from './Svg';

export default function Message(props) {
  return (
    <Svg
      {...props}
      d="M102.59,22.923c0.018,-0.601 -0.19,-1.21 -0.628,-1.688c-0.268,-0.293 -0.592,-0.504 -0.941,-0.631l-0.043,-0.015l-0.015,-0.005l-0.031,-0.01l-0.012,-0.004c-0.228,-0.073 -0.472,-0.112 -0.725,-0.112l-88.391,-0c-1.323,-0 -2.395,1.072 -2.395,2.395l0,62.611c0,3.357 2.721,6.078 6.078,6.078l81.024,0c3.358,0 6.079,-2.721 6.079,-6.078l0,-62.541Zm-38.553,36.325l-6.42,5.884c-0.915,0.84 -2.321,0.84 -3.236,0l-6.417,-5.882l-30.001,27.502l76.077,0l-30.003,-27.504Zm-49.838,24.457l30.221,-27.704l-30.221,-27.705l0,55.409Zm53.382,-27.706l30.219,27.702l0,-55.402l-30.219,27.7Zm26.459,-30.751l-76.078,0l31.568,28.94l0.025,0.022c0.015,0.013 0.03,0.027 0.045,0.041l0.032,0.03l6.367,5.837l38.041,-34.87Z"
    />
  );
}
